export default [
  // {
  //   key: 'name',
  //   label: 'field.name',
  //   type: 'text',
  // },
  // {
  //   key: 'username',
  //   label: 'field.username',
  //   type: 'text',
  // },
  // {
  //   key: 'isEnable',
  //   label: 'field.status',
  //   rules: 'required',
  //   type: 'radio',
  //   options: [
  //     { text: 'status.active', value: '1' },
  //     { text: 'status.inactive', value: '0' },
  //   ],
  // },
]
